import React from 'react';
import PropTypes from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';
import { useLocation } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SermonSummary from '../components/SermonSummary';
import MoreMedia from '../components/MoreMedia';

const useStyles = makeStyles(() => ({
  player: {
    '& audio': {
      width: '100%',
      '&:focus': {
        outline: 'unset',
      },
    },
  },
}));

const Page = ({ pageContext }) => {
  const classes = useStyles();

  const { search } = useLocation();
  const { start, end } = Object.fromEntries(new URLSearchParams(search));

  const { sermon } = pageContext;

  let { audioUrl } = sermon;
  if (start) {
    audioUrl += `#t=${start}`;
    if (end) {
      audioUrl += `,${end}`;
    }
  }

  return (
    <Layout>
      <SEO title={sermon.title} />

      <Box my={4} className={classes.player}>
        <ReactAudioPlayer autoPlay controls src={audioUrl} />
      </Box>

      <Box mb={8}>
        <SermonSummary audio sermon={sermon} />
      </Box>

      <MoreMedia title="More Recent Media" currentSermon={sermon} />
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Page;
